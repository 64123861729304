<template>
    <div>
        <div>
            <div class="text-xs-center">
                <h1 class="sz-kath-title">{{Pfarreien['title']}}</h1>
            </div>
            <div class="dekanat-bereiche">
                <div
                        v-for="(Bereich, key) in Pfarreien.DekanatBereiche"
                        :key="key"
                        class="dekanat-bereich"
                >
                    <div class="dekanat-ortschaften" >
                        <h2 class="text-xs-center ">{{Bereich.name}}</h2>
                        <div v-for="(Dekanat, key) in Bereich.Dekanate"
                                :key="key"

                                class="kath-sz-card dekanat-ortschaft-eintrag link text--darken-4"
                        >
                            <div @click.stop="toggleExpanded(Dekanat.phone)">
                                <div>{{Dekanat['Ort']}} </div>
                                <div v-if="Dekanat.expanded">
                                    {{Dekanat['Pfarramt']}} <br>
                                    {{Dekanat['Strasse']}} <br>
                                    {{Dekanat['PLZ']}} <br>
                                    {{Dekanat['phone']}} <br>
                                    {{Dekanat['Fax']}}<br>
                                    <div v-if="Dekanat.hasOwnProperty('oeffnungszeiten')"> {{Dekanat['oeffnungszeiten']}}</div>
                                    <div @click="mailTo(Dekanat['email'])">{{Dekanat['email']}}</div>
                                    <div @click="navPage(Dekanat['website'])">{{Dekanat['website']}}</div>
                                    <div @click="navPage(Dekanat['Details'])">Auf kath.ch ansehen</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "pfarreienComp",
        computed: {
            Pfarreien () {
                return this.$store.getters.Pfarreien
            }
        },
        methods: {
            toggleExpanded(phone){
                this.$store.dispatch('toggleExpanded', phone);
            },
            navPage(link){
                window.open('https://' + link, '_')
            },
            mailTo (email) {
                window.open('mailto:' + email, '_blank');
            }
        }
    }
</script>

<style scoped>

</style>